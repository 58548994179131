<template>
  <SelectField
    :multiselect="{ options, placeholder: '' }"
    v-bind="$props"
    @input="$emit('input', $event)"
  />
</template>

<script>
import BaseField from '../form/BaseField.vue';
import SelectField from '../form/SelectField.vue';

export default {
  extends: BaseField,

  components: { SelectField },

  computed: {
    options() {
      return this.$store.getters['roles/get']().map((role) => ({
        value: role.code,
        label: role.name,
      }));
    },
  },

  beforeMount() {
    this.$store.dispatch('roles/maybeFetch');
  },
};
</script>
