<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <Table
      class="align-middle"
      rowKey="id"
      :isLoading="$store.getters['announcements/isFetching']($route.query)"
      :columns="columns"
      :rows="$store.getters['announcements/get']($route.query)"
    >
      <template #top>
        <div class="p-16 border-bottom d-flex">
          <AnnouncementFilter />
          <router-link
            class="ms-auto"
            to="/admin/pengumuman/tambah"
            v-tooltip="'Tambah'"
            ><PlusCircleIcon
          /></router-link>
        </div>
      </template>

      <template #bottom>
        <div class="border-top p-16" v-if="meta && meta.last_page > 1">
          <Pagination :current="meta.current_page" :last="meta.last_page" />
        </div>
      </template>
    </Table>
  </main>
</template>

<script>
import announcementRepository from '../../../api/repositories/announcementRepository';

import AnnouncementFilter from '../../../components/admin/announcement/AnnouncementFilter.vue';
import Table from '../../../components/table/Table.vue';
import Pagination from '../../../components/table/Pagination.vue';
import RowActions from '../../../components/table/RowActions.vue';

import PencilIcon from 'bootstrap-icons/icons/pencil.svg';
import PlusCircleIcon from 'bootstrap-icons/icons/plus-circle.svg';
import TrashIcon from 'bootstrap-icons/icons/trash.svg';

export default {
  components: { AnnouncementFilter, Table, Pagination, PlusCircleIcon },

  metaInfo: { title: 'Pengumuman' },

  computed: {
    columns() {
      return [
        { key: 'title', text: 'Judul' },
        {
          key: 'file',
          text: 'File',
          toHTML: (row) =>
            row.file &&
            `<a class="btn btn-sm btn-outline-secondary" href="${row.fileURL}" target="_blank">Download</a>`,
        },
        {
          key: 'roles',
          text: 'Role',
          toHTML: (row) => row.roles.map(({ name }) => name).join(', '),
        },
        {
          key: 'created_at',
          text: 'Tanggal',
          toHTML: (row) => row.createdAtJS.format('YYYY-MM-DD HH:mm'),
        },
        {
          key: 'actions',
          component: RowActions,
          componentProps: {
            actions: () => [
              {
                icon: PencilIcon,
                text: 'Ubah',
                onClick: ({ row }) => this.$router.push(row.editRoutePath),
              },
              {
                icon: TrashIcon,
                text: 'Hapus',
                attrs: { class: 'text-danger' },
                onClick: this.handleRemoveClick,
              },
            ],
          },
          props: { class: 'text-end' },
        },
      ];
    },

    meta() {
      return this.$store.getters['announcements/getMeta'](this.$route.query);
    },
  },

  methods: {
    handleRemoveClick({ row }) {
      this.$confirmation.confirm(
        `Anda yakin mau menghapus <b>${row.title}</b>?`,
        {
          onOK: async () => {
            try {
              await announcementRepository.destroy(row.id);
              this.$store.dispatch(
                'announcements/fetchAndInvalidate',
                this.$route.query
              );
              this.$alert.success('Pengumuman berhasil dihapus');
            } catch (error) {
              this.$alert.requestError(error);
            }
          },
        }
      );
    },

    fetch() {
      this.$store.dispatch('announcements/maybeFetch', this.$route.query);
    },
  },

  beforeMount() {
    this.fetch();
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
};
</script>
