<template>
  <div class="wrapper d-flex align-items-center">
    <VDropdown
      class="d-inline-flex align-items-center me-16"
      @apply-show="focusToField"
    >
      <!-- This will be the popover reference (for the events and position) -->
      <button
        class="btn-plain d-inline-flex align-items-center"
        v-tooltip="'Cari'"
      >
        <SearchIcon />
      </button>

      <!-- This will be the content of the popover -->
      <template #popper>
        <div class="popper-content p-16">
          <InputField
            desc="Cari judul atau deskripsi"
            label="Cari"
            v-model="input.search"
          />
          <RoleSelectField
            class="mt-16"
            label="Role"
            v-model="input.filter.role_code"
          />
        </div>
      </template>
    </VDropdown>

    <ActiveFilters :activeFilters="activeFilters" @remove="removeFilter" />
  </div>
</template>

<script>
import Role from '../../../store/models/Role';

import InputField from '../../form/InputField.vue';
import RoleSelectField from '../../role/RoleSelectField.vue';
import ActiveFilters from '../../table/ActiveFilters.vue';
import BaseFilter from '../../table/BaseFilter.vue';
import VDropdown from '../../v-tooltip/VDropdown.vue';

import SearchIcon from 'bootstrap-icons/icons/search.svg';

export default {
  extends: BaseFilter,

  components: {
    InputField,
    RoleSelectField,
    ActiveFilters,
    VDropdown,
    SearchIcon,
  },

  methods: {
    /**
     * @see BaseFilter.activeFilters
     */
    getActiveFilterRoleCode(value) {
      const role = Role.find(value);

      if (role) {
        return {
          label: 'Role',
          key: 'role_code',
          value: role.name,
        };
      }
    },

    /**
     * @see BaseFilter.parseFilter
     */
    parseFilterRoles(result, value) {
      result.role_code = value && value.code;
    },

    /**
     * @see BaseFilter.prepareFilter
     */
    prepareFilterRoleCode(result, value) {
      result.roles = { ...result.roles, code: value };
    },
  },
};
</script>
